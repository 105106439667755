import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withRouter, useLocation } from 'react-router-dom';
import Episode from './episode/Episode';
import { gql, useQuery, useMutation } from '@apollo/client';
import ButtonAppBar from './NavBar';
import TextField from '@material-ui/core/TextField';
import '@fontsource/roboto';
import '@fontsource/roboto/700.css';
import { makeStyles } from '@material-ui/core/styles';
import { hideClosedEpisodesButton, showClosedEpisodesButton } from '../constants/Strings';
import { Alert, Snackbar } from '@mui/material';
import { CircularProgressBar } from './progressbar/CircularProgressBar';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import { defaultBlue } from '../Styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(50vh - 125px)',
    marginBottom: '4rem',
  },
  header: {
    fontFamily: 'roboto',
    fontWeight: '700',
    paddingBottom: theme.spacing(1),
  },
  helpLink: {
    position: 'relative',
    top: '-1rem',
    fontSize: '11pt',
    color: '#4a5665',
  },
  instructions: {
    margin: theme.spacing(4, 1, 2),
    fontSize: '15pt',
    color: '#4a5665',
  },
  continueButton: {
    margin: theme.spacing(1, 0, 1),
    float: 'right',
  },
  callToAction: {
    marginBottom: theme.spacing(2),
  },
  expandedForm: {
    marginTop: theme.spacing(2),
  },
}));

function SignedInHome(props) {
  const classes = useStyles();

  const [value, setValue] = useState('');
  const [showClosedEpisodes, setShowClosedEpisodes] = useState(false);
  const [showClosedEpisodesButtonText, setShowClosedEpisodesButtonText] = useState(
    showClosedEpisodes ? hideClosedEpisodesButton : showClosedEpisodesButton
  );
  const [showClosedEpisodesButtonIsVisible, setShowClosedEpisodesButtonIsVisible] = useState(false);
  const [showProgressBar, setShowProgressbar] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [fullname, setFullname] = useState('');
  const [state, setState] = useState('');
  const [language, setLanguage] = useState('en');
  const [email, setEmail] = useState('');
  const location = useLocation();
  const shouldPollNextAppointment = location.state?.shouldPollNextAppointment ? location.state?.shouldPollNextAppointment : false;

  const [isFormExpanded, setIsFormExpanded] = useState(false);

  const openForm = () => {
    setIsFormExpanded(true);
  };

  const closeForm = () => {
    setIsFormExpanded(false);
  };

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  const showClosedEpisodesHandler = () => {
    setShowClosedEpisodes(!showClosedEpisodes);
    showClosedEpisodes
      ? setShowClosedEpisodesButtonText(showClosedEpisodesButton)
      : setShowClosedEpisodesButtonText(hideClosedEpisodesButton);
  };

  const CREATE_EPISODE = gql`
  mutation CreateUserEpisode($description: String!) {
      createUserEpisode(input: { description: $description }) {
        id
        scheduleURL
      }
    }
  `;
  const [newEpisode, { data: newEpisodeData, newEpisodeLoading, newEpisodeError }] = useMutation(CREATE_EPISODE, {
    onError: error => {
      setCreateEpisodeError(error.message);
    },
  });

  const [createEpisodeError, setCreateEpisodeError] = useState('');

  const episodeHandler = e => {
    e.preventDefault();

    newEpisode({
      variables: {
        description: value,
      },
    });
  };

  // Episode creation state variables.
  if (newEpisodeError) {
    console.log('error occurred');
  }
  if (newEpisodeLoading) {
    console.log('loading');
  }

  if (newEpisodeData) {
    props.history.push({
      pathname: '/schedule/' + newEpisodeData.createUserEpisode.id,
      state: {
        scheduleURL: newEpisodeData.createUserEpisode.scheduleURL,
      },
    });
  }

  const ME = gql`
    {
      me {
        isEnrolled
        firstname
        lastname
        email
        state
        language
      }
    }
  `;

  const OPEN_EPISODES = gql`
    {
      getOpenUserEpisodes {
        id
        description
        createdAt
        closedAt
        scheduleURL
        trends {
          name
          x
          y
        }
        evaluations {
          id
          createdAt
          plan
          treatmentPathways
          signedAt
        }
        appointments
        treatments {
          id
          region
          name
          videos {
            id
            url
          }
        }
      }
    }
  `;

  const CLOSED_EPISODES = gql`
    {
      getClosedUserEpisodes {
        id
        description
        createdAt
        closedAt
        trends {
          name
          x
          y
        }
        evaluations {
          id
          createdAt
          plan
          treatmentPathways
          signedAt
        }
        appointments
        treatments {
          id
          region
          name
          videos {
            id
            url
          }
        }
      }
    }
  `;

  const { data: userData, loading: userLoading, error: userError } = useQuery(ME);
  const { data: openEpisodesData, loading: openEpisodesLoading, error: openEpisodesError } = useQuery(OPEN_EPISODES);
  const { data: closedEpisodesData, loading: closedEpisodesLoading, error: closedEpisodesError } = useQuery(CLOSED_EPISODES);

  useEffect(() => {
    if (userLoading || openEpisodesLoading || closedEpisodesLoading) {
      setShowProgressbar(true);
    } else if (!userLoading && !openEpisodesLoading && !closedEpisodesLoading) {
      setShowProgressbar(false);
    }
    if (userData && openEpisodesData && closedEpisodesData) {
      setShowProgressbar(false);
      if (closedEpisodesData) {
        setShowClosedEpisodesButtonIsVisible(closedEpisodesData.getClosedUserEpisodes.length > 0);
      }
    }
    if (userError || openEpisodesError || closedEpisodesError) {
      setShowSnackbar(true);
    }
    if (userData && userData.me) {
      setState(userData.me.state);
      setLanguage(userData.me.language);
      setFullname(userData.me.fullname);
      setEmail(userData.me.email);
    }
  }, [
    userLoading,
    openEpisodesLoading,
    closedEpisodesLoading,
    userData,
    openEpisodesData,
    closedEpisodesData,
    userError,
    openEpisodesError,
    closedEpisodesError,
  ]);

  if (userError) return `an error occurred: ${userError.message}`;
  if (openEpisodesError) return `an error occurred: ${openEpisodesError.message}`;

  const hasEpisodes = openEpisodesData?.getOpenUserEpisodes.length > 0 || closedEpisodesData?.getClosedUserEpisodes.length > 0;

  return (
    <>
      <ButtonAppBar shouldPollNextAppointment={false} />
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <Grid container spacing={3}>
            {(() => {
              if (userData) {
                // console.log("userData: ", userData);
                // console.log("openEpisodesData: ", openEpisodesData);
                // console.log("closedEpisodesData: ", closedEpisodesData);

                return (
                  <Grid item xs={12} sm={12}>
                    {userData.me.firstname ? <h1>Welcome, {userData.me.firstname}!</h1> : <h1>Welcome to Aware Health</h1>}
                    <p className={classes.helpLink}>
                      Need help? Email our team at <a href="mailto:support@awarehealth.io">support@awarehealth.io</a>.
                    </p>

                    {hasEpisodes && !openEpisodesLoading && (
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '1rem' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'calc(50% - 8px/2)' }}>
                          <Button
                            style={{
                              backgroundColor: isFormExpanded ? 'transparent' : defaultBlue,
                              color: isFormExpanded ? defaultBlue : 'white',
                              borderColor: defaultBlue,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            variant="outlined"
                            size="medium"
                            fullWidth
                            onClick={closeForm}
                          >
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <FactCheckOutlinedIcon style={{ margin: '5px 0' }} />
                              <Box sx={{ fontWeight: 500, textAlign: 'center', lineHeight: 1.2, my: '5px' }}>
                                View{' '}
                                <Box component="span" sx={{ display: { xs: 'none', sm: 'inline' } }}>
                                  Your
                                </Box>{' '}
                                Episodes
                              </Box>
                            </Box>
                          </Button>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'calc(50% - 8px/2)' }}>
                          <Button
                            style={{
                              backgroundColor: isFormExpanded ? defaultBlue : 'transparent',
                              color: isFormExpanded ? 'white' : defaultBlue,
                              borderColor: defaultBlue,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            variant="outlined"
                            size="medium"
                            fullWidth
                            onClick={openForm}
                          >
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <AddIcon style={{ margin: '5px 0' }} />
                              <Box sx={{ fontWeight: 500, textAlign: 'center', lineHeight: 1.2, my: '5px' }}>
                                Log A{' '}
                                <Box component="span" sx={{ display: { xs: 'none', sm: 'inline' } }}>
                                  New
                                </Box>{' '}
                                Problem
                              </Box>
                            </Box>
                          </Button>
                        </Box>
                      </Box>
                    )}

                    {(!hasEpisodes || isFormExpanded) && !openEpisodesLoading && !closedEpisodesLoading && (
                      <div className={classes.expandedForm}>
                        <div className={classes.instructions}>
                          Which symptoms or reasons are you seeking care for, {userData.me.firstname}?
                        </div>
                        <TextField
                          id="outlined-multiline-flexible"
                          label="Describe symptoms"
                          multiline
                          minRows={2}
                          variant="outlined"
                          style={{ width: '100%' }}
                          defaultValue=""
                          onChange={handleChange}
                          helperText={createEpisodeError}
                          FormHelperTextProps={{ style: { color: 'red' } }}
                        />
                        <Button
                          style={{
                            backgroundColor: defaultBlue,
                          }}
                          className={classes.continueButton}
                          color="primary"
                          variant="contained"
                          size="medium"
                          onClick={episodeHandler}
                        >
                          Submit
                        </Button>
                      </div>
                    )}
                  </Grid>
                );
              }
            })()}

            {!hasEpisodes && !isFormExpanded && !openEpisodesLoading && !closedEpisodesLoading && (
              <Box
                sx={{
                  backgroundColor: 'white',
                  mx: 1.5,
                  px: { xs: 2, sm: 3 },
                  py: 2,
                  border: '1px solid #eeeeee',
                  borderRadius: '4px',
                  boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
                }}
              >
                <Typography variant="body1">You don't have any episodes yet. Log a new problem to get started.</Typography>
              </Box>
            )}

            {openEpisodesData &&
              !openEpisodesLoading &&
              !isFormExpanded &&
              openEpisodesData.getOpenUserEpisodes.map(episode => {
                return (
                  <Episode
                    key={episode.id}
                    episode={episode}
                    state={state}
                    language={language}
                    fullname={fullname}
                    email={email}
                    scheduleURL={episode.scheduleURL}
                    numVisits={episode.evaluations.length}
                  />
                );
              })}

            {showClosedEpisodesButtonIsVisible && !closedEpisodesLoading && !isFormExpanded && (
              <Grid item xs={12} sm={12}>
                <Button
                  style={{ backgroundColor: '#6d6d6d' }}
                  variant="contained"
                  size="medium"
                  color="primary"
                  href={''}
                  target="_blank"
                  rel="noreferrer"
                  onClick={showClosedEpisodesHandler}
                >
                  {showClosedEpisodesButtonText}
                </Button>
              </Grid>
            )}

            {showClosedEpisodes &&
              closedEpisodesData &&
              closedEpisodesData.getClosedUserEpisodes.map(episode => {
                return (
                  <Episode
                    key={episode.id}
                    episode={episode}
                    showClosedEpisode={true}
                    shouldPollNextAppointment={shouldPollNextAppointment}
                  />
                );
              })}
          </Grid>
        </div>
      </Container>
      <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" disabled={showProgressBar} sx={{ width: '100%' }}>
          Error retrieving data.
        </Alert>
      </Snackbar>
      {CircularProgressBar(showProgressBar)}
    </>
  );
}

export default withRouter(SignedInHome);
